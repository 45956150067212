import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Layout from "../Util/Layout";

const ExperiencePage = () => {
  const careerMilestones = [
    {
      startYear: "June 2024",
      endYear: "Present",
      title: "Software Engineer 1, autonomy at ALA Engineering",
      description:
        "Working on development of an Autonomy stack for a fully self driving cabinless cattle feeding truck. Developing vision processing models to inform autonomous decision making by blending camera, lidar, radar, and GPS sensor inputs.",
    },
    {
      startYear: "May 2023",
      endYear: "June 2024",
      title: "Junior Software Engineer at Drone Amplified",
      description:
        "Spearheaded the development of web applications and scripts for customer troubleshooting, data collection, and automating inventory tasks. My inventory tool suite saves the company ~20 hours of labor weekly, or ~$1,000 per week, while the external tools improve customer relations by allowing simplified troubleshooting and collects valuable data for future use in the training of machine learning models.",
    },
    {
      startYear: "January 2022",
      endYear: "January 2023",
      title: "Software Engineering Intern at Sandhills Global",
      description:
        "Developed and tested full stack .Net applications communicating with SQL databases. Daily operations included working in a SCRUM environment alongside a team of experienced engineers to maintain and update pre-existing software, as well as release new projects.",
    },
    {
      startYear: "April 2021",
      endYear: "October 2021",
      title: "Software Development Intern at Diabase Engineering",
      description:
        "Engineered Python plugins for pre-existing 3D printing applications to make them compatible with Diabase machines, and launched a full stack ReactJS based application for Gcode Manipulation. Experienced working with advanced 3D printing technologies in an emerging market.",
    },
  ];

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Layout>
      <Box p={3}>
        <Typography variant="h3" gutterBottom>
          My Engineering Experience
        </Typography>

        {/* Interactive Timeline */}
        <Box sx={{ position: "relative", margin: "20px 0" }}>
          {careerMilestones.map((milestone, index) => (
            <Accordion
              square
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              key={index}
              sx={{
                margin: "10px 0",
                border: "none",
                boxShadow: "none",
                "&:before": {
                  display: "none",
                },
                "&.Mui-expanded": {
                  margin: "10px 0",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
                sx={{
                  padding: 0,
                  minHeight: "48px",
                  "& .MuiAccordionSummary-content": {
                    margin: 0,
                  },
                  "& .Mui-expanded": {
                    minHeight: "48px",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                    padding: "10px 30px",
                    borderBottom: "1px solid #D0D0D0",
                    background: "#f7f7f7",
                    borderRadius: "5px",
                  }}
                >
                  <Typography variant="h6">{`${milestone.startYear} - ${milestone.endYear}`}</Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    {milestone.title}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "0 30px 15px" }}>
                <Typography>{milestone.description}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </Layout>
  );
};

export default ExperiencePage;
