import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import Layout from "../Util/Layout";

const AiPremiumCalculationPage = () => {
  return (
    <Layout>
      <Box p={3}>
        <Typography variant="h3" gutterBottom>
          AI Premium Calculation - National Indemnity
        </Typography>

        <Typography variant="h4" color="textSecondary" paragraph>
          My Role: Product Manager
        </Typography>

        {/* Project Description */}
        <Typography variant="h5" gutterBottom>
          Project Overview
        </Typography>
        <Typography paragraph>
          This project envisions an automated data ingestion system that taps
          into telematics data via APIs, and uses a risk-analysis model to
          recommend commercial insurance premiums nationwide. Information such
          as driver history, routes taken, value of cargo, etc., are all fed in
          to a custom classification model to determine a risk category for
          commercial drivers. This information is then taken into account by
          insurance agents. The hours and dollars saved by National Indemnity
          are adding up as the model improves.
        </Typography>

        {/* Technologies Used */}
        <Typography variant="h5" gutterBottom>
          Technologies Used
        </Typography>
        <ul>
          <li>
            <Typography>
              <strong>Python:</strong> We take advantage of the vast array of
              machine learning libraries that Python has to offer. We customized
              individual layers of a deep neural network using Pandas.
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>API :</strong> We tap into telematics data streams via API
              to create a well-rounded profile of commercial drivers.
            </Typography>
          </li>
        </ul>

        {/* What I Learned */}
        <Typography variant="h5" gutterBottom>
          What I Learned
        </Typography>
        <Typography paragraph>
          In this project, I have learned an immense amount about being a
          leader. As a product manager, I was not only responsible for
          determining what the development team should build, but I was also in
          charge of conflict resolution and organization. I learned that I am
          far more capable of doing these things than I had thought. On the more
          technical side, this project has been my first non-theoretical work
          with AI. Getting the chance to apply all of the theory that I have
          studied has helped me learn to bridge the gap between computer science
          theory and application.
        </Typography>

        {/* Application Features */}
        <Typography variant="h5" gutterBottom>
          Features and Functionality
        </Typography>
        <Grid container spacing={2}>
          {/* Feature 1 */}
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} p={2}>
              <Typography variant="h6">Premium Suggestion</Typography>
              <Typography paragraph>
                The trained model suggests commercial insurance premiums for
                drivers based on a selection of telematics data. This is used by
                National Indemnity representatives as a suggestion while
                determining driver's rates.
              </Typography>
            </Paper>
          </Grid>

          {/* Feature 2 */}
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} p={2}>
              <Typography variant="h6">Data Visualization</Typography>
              <Typography paragraph>
                The team is working on using our large access to telematics data
                to provide insights into the average driver's safety, and other
                valuable metrics.
              </Typography>
            </Paper>
          </Grid>
          {/* Add more features as needed */}
        </Grid>

        {/* Additional Sections */}
        {/* Consider adding sections like 'Challenges Faced', 'Future Improvements', etc. */}
      </Box>
    </Layout>
  );
};

export default AiPremiumCalculationPage;
