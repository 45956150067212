import React from 'react';

export const DroneIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="12" cy="12" r="3"/> {/* Central body of the drone */}
    <line x1="12" y1="12" x2="8" y2="8"/> {/* Top-left propeller arm */}
    <circle cx="7" cy="7" r="4"/> {/* Top-left propeller */}
    <line x1="12" y1="12" x2="16" y2="8"/> {/* Top-right propeller arm */}
    <circle cx="17" cy="7" r="4"/> {/* Top-right propeller */}
    <line x1="12" y1="12" x2="16" y2="16"/> {/* Bottom-right propeller arm */}
    <circle cx="17" cy="17" r="4"/> {/* Bottom-right propeller */}
    <line x1="12" y1="12" x2="8" y2="16"/> {/* Bottom-left propeller arm */}
    <circle cx="7" cy="17" r="4"/> {/* Bottom-left propeller */}
  </svg>
);

export const CalculatorIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"/>
    <line x1="6" y1="10" x2="18" y2="10"/>
    <line x1="6" y1="14" x2="10" y2="14"/>
    <line x1="14" y1="14" x2="18" y2="14"/>
    <line x1="6" y1="18" x2="10" y2="18"/>
    <line x1="14" y1="18" x2="18" y2="18"/>
    <line x1="10" y1="6" x2="14" y2="6"/>
  </svg>
);

export const ToolkitIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <rect x="2" y="7" width="20" height="14" rx="2" ry="2" />
    <path d="M16 3h-8v4h8V3z" />
  </svg>
);

export const BoatSensorIcon = ({ style }) => (
  <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <path d="M21 21H3v-2h18v2z"/> {/* Base of the boat */}
    <path d="M12 2L3 10h18L12 2z"/> {/* Sail */}
    <line x1="12" y1="2" x2="12" y2="15"/> {/* Mast */}
    <polygon points="6 15, 18 15, 12 21" fill="currentColor"/> {/* Hull */}
  </svg>
);


export const NetworkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <ellipse cx="12" cy="7" rx="8" ry="3"/> {/* Top ellipse */}
    <path d="M4 7v6c0 1.657 3.582 3 8 3s8-1.343 8-3V7"/> {/* Vertical lines for the top layer */}
    <ellipse cx="12" cy="13" rx="8" ry="3"/> {/* Middle ellipse */}
    <path d="M4 13v6c0 1.657 3.582 3 8 3s8-1.343 8-3v-6"/> {/* Vertical lines for the middle layer */}
    <ellipse cx="12" cy="19" rx="8" ry="3"/> {/* Bottom ellipse */}
  </svg>
);

export const WebIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <g>
      <g>
        <path d="M256,0C114.62,0,0,114.62,0,256s114.62,256,256,256s256-114.62,256-256S397.38,0,256,0z M172.211,41.609
                c-24.934,27.119-44.68,66.125-56.755,111.992H49.749C75.179,102.741,118.869,62.524,172.211,41.609z M25.6,256
                c0-26.999,5.077-52.727,13.662-76.8h70.494c-4.608,24.294-7.356,49.963-7.356,76.8s2.748,52.506,7.347,76.8H39.262
                C30.677,308.727,25.6,283,25.6,256z M49.749,358.4h65.707c12.083,45.867,31.821,84.872,56.755,111.991
                C118.869,449.476,75.179,409.259,49.749,358.4z M243.2,485.188c-43.81-8.252-81.877-58.24-101.359-126.788H243.2V485.188z
                M243.2,332.8H135.74c-4.924-24.166-7.74-49.997-7.74-76.8s2.816-52.634,7.74-76.8H243.2V332.8z M243.2,153.6H141.841
                C161.323,85.052,199.39,35.063,243.2,26.812V153.6z M462.251,153.6h-65.707c-12.083-45.867-31.821-84.873-56.755-111.992
                C393.131,62.524,436.821,102.741,462.251,153.6z M268.8,26.812c43.81,8.252,81.877,58.24,101.359,126.788H268.8V26.812z
                M268.8,179.2h107.46c4.924,24.166,7.74,49.997,7.74,76.8s-2.816,52.634-7.74,76.8H268.8V179.2z M268.8,485.188V358.4h101.359
                C350.677,426.948,312.61,476.937,268.8,485.188z M339.789,470.391c24.934-27.127,44.672-66.125,56.755-111.991h65.707
                C436.821,409.259,393.131,449.476,339.789,470.391z M402.244,332.8c4.608-24.294,7.356-49.963,7.356-76.8
                s-2.748-52.506-7.347-76.8h70.494c8.576,24.073,13.653,49.801,13.653,76.8c0,27-5.077,52.727-13.662,76.8H402.244z"/>
      </g>
    </g>
  </svg>
);




