import { createTheme } from "@mui/material/styles";

// Custom theme configuration
const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6", // A professional blue tone
    },
    secondary: {
      main: "#19857b", // A complementary green tone
    },
    error: {
      main: "#ff5252",
    },
    background: {
      default: "#fff",
      paper: "#f5f5f5",
    },
    text: {
      primary: "#333",
      secondary: "#555",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "2.5rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "2.2rem",
      fontWeight: 500,
    },
    h3: {
      fontSize: "1.8rem",
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    button: {
      textTransform: "none",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 5,
      },
    },
  },
});

export default theme;
