import React from "react";
import { Box, Typography } from "@mui/material";
import Layout from "../Util/Layout";
import HoleToCode from "../../Assets/HoleToCode.png";

const PersonalWebsitePage = () => {
  return (
    <Layout>
      <Box p={3}>
        <Typography variant="h3" gutterBottom>
          Personal Website - Personal Project
        </Typography>

        <Typography variant="h4" color="textSecondary" paragraph>
          My Role: Solo Developer
        </Typography>

        {/* Project Description */}
        <Typography variant="h5" gutterBottom>
          Project Overview
        </Typography>
        <Typography paragraph>
          This is iteration 3 of my personal website. Iteration 1 was made many
          years ago before I had any formal development training. It was a
          single page website designed using just html and CSS. On iteration 2,
          I decided to make the jump to React. This marked a significant
          milestone in my responsive web development education. Because it was
          my first ever react project, visiting it again after years of
          experience in web development revealed many flaws. The page was not
          mobile responsive, the user experience was lacking, and the design
          principles were inconsistent. I created version 3 with the hopes of
          addressing these issues.
        </Typography>

        <Box my={3}>
          <img
            src={HoleToCode}
            alt="Website Architecture Diagram"
            style={{
              maxWidth: "100%",
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </Box>
        {/* What I Learned */}
        <Typography variant="h5" gutterBottom>
          What I Learned
        </Typography>
        <Typography paragraph>
          Creating version 3 was a chance to address these issues and apply
          everything I've learned over the years. It wasn't just about using
          more advanced technologies or following the latest trends. It was a
          reflection of my personal and professional growth. This version is
          more than just a website; it's a testament to my journey in web
          development, showcasing my progress from a beginner to a more seasoned
          developer. The process was enlightening, challenging, and incredibly
          rewarding.
        </Typography>
      </Box>
    </Layout>
  );
};

export default PersonalWebsitePage;
