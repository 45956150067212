import React from "react";
import { Box, Typography } from "@mui/material";
import Layout from "../Util/Layout";
import UNLEngineering from "../../Assets/UNLEngineering.jpg";
import EducationMindMap from "../../Assets/EducationMindMap.png";

const EducationPage = () => {
  return (
    <Layout>
      <Box p={3}>
        <Typography variant="h3" gutterBottom>
          My Education Journey
        </Typography>

        <Typography variant="h4" color="textSecondary" paragraph>
          University of Nebraska-Lincoln
        </Typography>

        <Typography variant="h5" gutterBottom>
          Overview
        </Typography>
        <Typography paragraph>
          I received my Bachelors of Science in Software Engineering from the 
          University of Nebraska-Lincoln in 2024.  My focus areas
          were quite extensive, diving deep into Artificial Intelligence &
          Machine Learning, Data Science & Informatics, and Foundations of
          Computer Science.
        </Typography>
        <Typography paragraph>
          Throughout my academic journey, I was recognized for excellence on
          multiple occasions. Two examples of this are being placed on the
          Dean's List and being a George-Beadle Scholar. Both of these were
          milestones that marked my dedication and passion for the field of
          software engineering.
        </Typography>

        <Box my={3}>
          <img
            src={UNLEngineering}
            alt="University Logo"
            style={{
              maxWidth: "70%",
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </Box>

        <Typography variant="h5" gutterBottom>
          Beyond the Curriculum
        </Typography>
        <Typography paragraph>
          My educational experience wasn't just about the core subjects. I also
          took interest-based courses like "Intensive Spanish Conversation" and
          "Introduction to Astronomy". These courses allowed me to explore
          different perspectives and broaden my knowledge base beyond the
          technical field.
        </Typography>
        <Typography paragraph>
          While studying at UNL, I was frequently engaged in extracurricular
          activities. I was an active member of the Judo club and I
          participated in the unofficial BJJ club. These activities helped me in
          building teamwork and leadership skills, while also providing a
          much-needed balance to academic life.
        </Typography>

        <Box my={3}>
          <img
            src={EducationMindMap}
            alt="Education Mind Map"
            style={{
              maxWidth: "70%",
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </Box>

        <Typography variant="h5" gutterBottom>
          Reflecting on My Journey
        </Typography>
        <Typography paragraph>
          My time at the University of Nebraska-Lincoln has been a
          transformative phase in my life. It's here that I've laid a strong
          foundation in software engineering, complemented by my varied
          interests and extracurricular engagements. This journey has not only
          shaped my technical skills but also honed my interpersonal abilities,
          preparing me well for the challenges and opportunities that lie ahead
          in my professional career.
        </Typography>
      </Box>
    </Layout>
  );
};

export default EducationPage;
