import React from "react";
import { Box, Typography, Card, CardContent, Link } from "@mui/material";
import Layout from "../Util/Layout";

const ContactPage = () => {
  return (
    <Layout>
      <Box p={3} display="flex" justifyContent="center">
        <Card sx={{ maxWidth: 600 }}>  {/* Adjust the maxWidth as needed */}
          <CardContent>
            <Typography variant="h4" gutterBottom component="div">
              Contact Me
            </Typography>
            <Typography variant="body1" color="text.secondary">
              If you have any questions or would like to get in touch, feel free to contact me.
            </Typography>
            <Typography variant="h6" gutterBottom component="div" mt={2}>
              Email: <Link href="mailto:nickc1245@gmail.com">nickc1245@gmail.com</Link>
            </Typography>
            <Typography variant="h6" gutterBottom>
              Phone: <Link href="tel:+14087805704">408-780-5704</Link>
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Layout>
  );
};

export default ContactPage;
