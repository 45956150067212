import React from "react";
import { Container, CssBaseline, Box } from "@mui/material";
import NavigationBar from "./NavigationBar"; // Adjust path as needed
import Footer from "./Footer"; // Adjust path as needed

const Layout = ({ children }) => {
  return (
    <>
      <CssBaseline /> {/* Normalize CSS */}
      <NavigationBar />
      <Container maxWidth="lg">
        <Box component="main" sx={{ my: 4 }}>
          {children}
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default Layout;
