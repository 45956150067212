import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import Layout from "../Util/Layout";
import Outdoors from "../../Assets/Outdoors.jpg";
import BlueBelt from "../../Assets/BlueBelt.jpg";
import Tito from "../../Assets/Tito.jpg";
import Family from "../../Assets/Family.jpg";

const PersonalLifePage = () => {
  const imageStyle = {
    maxWidth: "80%", // Adjust this value as needed
    height: "auto",
    display: "block", // Centers the image in the grid
    marginLeft: "auto",
    marginRight: "auto",
  };
  return (
    <Layout>
      <Box p={3}>
        <Typography variant="h3" gutterBottom>
          A Glimpse into My Personal Life
        </Typography>

        <Grid container spacing={2}>
          {/* Outdoor Activities */}
          <Grid item xs={12} sm={8}>
            <Typography paragraph>
              Outside of technical work, I have a handful of passions and
              lifelong hobbies that define me. To start, I love just about all
              outdoor activities. I grew up in Colorado, and spent a lot of my
              time hiking, skiing, rafting, climbing, and just enjoying nature.
              I continue to spend time outside any chance I get.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <img
              src={Outdoors}
              alt="Hiking in the mountains"
              style={imageStyle}
            />
          </Grid>
          {/* Family Time */}
          <Grid item xs={12} sm={8}>
            <Typography variant="h5" gutterBottom>
              My Family
            </Typography>
            <Typography paragraph>
              Family is paramount to me. Though they live in Colorado, I try to
              visit them whenever possible. I am one of three kids, and I fall
              right in the middle. I have an older brother named Jake who is
              also a computer programmer, and a younger sister named Kate who is
              currently an undergraduate student at UNL. My mom Cary works as an
              ADHD counselor, helping those with ADHD achieve greater success in
              their daily life. My dad Tim is a lifetime tech industry worker.
              He works as a technical executive, guiding tech companies in the
              right direction. Each of my family members has worked to shape me
              as a person in different ways, and I will be forever thankful for
              everything they do to support me.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <img
              src={Family}
              alt="Spending time with family"
              style={imageStyle}
            />
          </Grid>
          {/* Martial Arts */}
          <Grid item xs={12} sm={8}>
            <Typography variant="h5" gutterBottom>
              My Martial Arts Journey
            </Typography>
            <Typography paragraph>
              Additionally, I have been practicing various forms of martial arts
              since the second grade. I started with Kempo karate, earning my
              black belt at 16, the youngest age that one can earn a black belt.
              I then moved into mixed martial arts and Brazilian Jiu-Jitsu,
              which I continue to practice today. I earned my blue belt in
              Brazilian Jiu-Jitsu in the summer of 2021 after a few
              COVID-related delays. This stemmed an interest in weight lifting,
              which I have been doing more seriously for a little over three
              years. I have recently gotten into Judo, taking 1st place in the Cornhusker 
              State Games for Judo. I plan to continue
              exploring various forms of martial arts and exercising for as long
              as my body will let me.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <img
              src={BlueBelt}
              alt="Practicing martial arts"
              style={imageStyle}
            />
          </Grid>

          {/* Tito the Rabbit */}
          <Grid item xs={12} sm={8}>
            <Typography variant="h5" gutterBottom>
              Keeper of "The Menace" - Tito the Rabbit
            </Typography>
            <Typography paragraph>
              In case anyone wonders who is responsible for consuming half of my 
              monthly expences - his name is Tito,
              and he joined the family in July 2021. His favorite activity is
              exploring, and his favorite food is bananas. He is just about the
              most spoiled little bunny you have ever seen, and I am always down
              to talk about him or share pictures. If you want to see more of
              him, check out his Instagram page @tito_the_bun!
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <img src={Tito} alt="Tito the rabbit" style={imageStyle} />
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default PersonalLifePage;
