import React from "react";
import { Box, Typography } from "@mui/material";
import Layout from "../Util/Layout";
import Nubss from "../../Assets/NUBSS.jpg";

const AWCSDRPage = () => {
  return (
    <Layout>
      <Box p={3}>
        <Typography variant="h3" gutterBottom>
          Aerial Water Collaborative Sensor Deployment and Recovery - NIMBUS Labs
          UNL
        </Typography>

        <Typography variant="h4" color="textSecondary" paragraph>
          My Role: Development Manager
        </Typography>

        {/* Project Description */}
        <Typography variant="h5" gutterBottom>
          Project Overview
        </Typography>
        <Typography paragraph>
          The team developed a boat that can assist an unmanned aerial system
          (UAS) in deploying and recovering underwater sensors. The UAS will be
          able to drop the boats and sensors into the water. The focus of this
          work was developing the electro-mechanical systems that enable the
          boat to recover the underwater sensor and then allow the UAS to
          recover the boat. This allows the multi-robot system to monitor both above
          and below the water in difficult environments.
        </Typography>

        <Box my={3}>
          <img
            src={Nubss}
            alt="NUBSS"
            style={{
              maxWidth: "100%",
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </Box>

        {/* Technologies Used */}
        <Typography variant="h5" gutterBottom>
          Technologies Used
        </Typography>
        <ul>
          <li>
            <Typography>
              <strong>ROS:</strong> ROS, or Robot Operating System, was used to
              control the state of the autonomous boat itself. This was done in
              the form of a state machine.
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>CAD:</strong> The team used CAD software to design and 3D
              print multiple devices on board. The body of the vessel itself was
              laser cut in-house after being thoughtfully designed.
            </Typography>
          </li>
        </ul>

        {/* What I Learned */}
        <Typography variant="h5" gutterBottom>
          What I Learned
        </Typography>
        <Typography paragraph>
          In this project, I learned a lot about what it takes to be a
          development manager. I spent a lot of my time during this year-long
          project coordinating efforts of other developers and overseeing our
          team's agile practices. In addition to that, I learned that I am
          extremely interested in the intersection of software and hardware.
          This lower level coding is extremely rewarding, and I would love to do
          more work like this moving forward.
        </Typography>

        {/* Additional Sections */}
        {/* Consider adding sections like 'Challenges Faced', 'Future Improvements', etc. */}
      </Box>
    </Layout>
  );
};

export default AWCSDRPage;
